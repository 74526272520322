const config = {
  rootProjectId: "root",
  uiBucketName: "b2btest.c.retter.io",
  appUrl: "https://api.b2btest.retter.io/",
  cosUrl: "api.b2btest.retter.io",
  version: "2.1.6",
  captchaKey: "6Le7VG0pAAAAALixdcMQJ2pLMtkHCSmyXHrQqaVR",
  stage: "PROD"
}
export default config

